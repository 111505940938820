import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import { Dialog } from "@blueprintjs/core"
import GetStarted from "../components/CTA/GetStarted"
import SEO from "../components/seo"
import PropTypes from "prop-types"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import { getClientCloudinaryMedia } from "../utils/utils"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import CloseIcon from "../components/CloseIcon"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"
import { getStarted } from "../cms/fields/getStarted"
var classNames = require("classnames")

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

import ImageGallery from "react-image-gallery"

const SingleCommunity = ({ data, pageContext, location }) => {
  var language
  if (pageContext && pageContext.language) {
    language = pageContext.language
  }
  const post = data.singleCommunity.nodes[0]

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)

  const toggleDialog = (prop) => {
    setIsDialogOpen(!isDialogOpen)
    setGalleryIndex(prop)
  }

  const handleYearChange = (prop) => (event) => {
    setYears({
      ...years,
      [prop]: event.target.checked
    })
  }

  let photos = {}
  let stateYears = {}
  var displayedPhotos = []
  var [years, setYears] = useState()

  if (post.gallery.usesYears) {
    // get photos for set year and create array of photos in photos object
    for (let i = 0; i < post.gallery.years.length; i++) {
      photos[post.gallery.years[i]] = getClientCloudinaryMedia(
        false,
        post.gallery.prefix + post.gallery.years[i],
        "media"
      )
      stateYears[post.gallery.years[i]] =
        location.pathname === "/community/wisdom/" ? true : false
      if (i === post.gallery.years.length - 1)
        stateYears[post.gallery.years[i]] = true
    }
    ;[years, setYears] = useState(stateYears)
    for (let i = 0; i < post.gallery.years.length; i++) {
      let year = post.gallery.years[i]
      if (photos[year] && years[year]) {
        displayedPhotos = displayedPhotos.concat(photos[year])
      }
    }
  } else if (post.gallery.prefix) {
    photos = getClientCloudinaryMedia(false, post.gallery.prefix, "media")
    displayedPhotos = photos
  }

  var reactGalleryPhotos = function (photos) {
    let arr = []
    for (let i = 0; i < photos.length; i++) {
      let obj = {}
      obj.original = `https://res.cloudinary.com/nuvolum/image/upload/q_60,w_1024/v1/${photos[i]}.jpg`
      arr.push(obj)
    }
    return arr
  }

  const playIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="play"
      class="svg-inline--fa fa-play fa-w-14 button-icon"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
      ></path>
    </svg>
  )

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />

        <div className="single-community">
          <Button buttonText={"BACK"} goBack href={"/community/"} />

          <div
            className="columns top-section white-back"
            style={{
              paddingBottom:
                location.pathname ===
                "/community/dental-implant-mini-residency-program/"
                  ? 0
                  : null
            }}
          >
            <div className="column is-2"></div>
            <div className="column">
              <h1
                className="mobile-left"
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{ __html: post.heading }}
              ></h1>

              {post.blurb && (
                <div className="columns">
                  <div className={`column is-3`}></div>
                  <div className="column">
                    <MarkdownViewer
                      markdown={post.blurb}
                      className="large has-text-centered"
                    />
                  </div>
                  <div className={`column is-3`}></div>
                </div>
              )}
            </div>
            <div className="column is-2"></div>
          </div>

          {post.mainVideoImagePair && (
            <div
              className="columns has-text-centered"
              style={{ paddingBottom: "4rem" }}
            >
              <div className="column is-3"></div>
              <div className="column is-full-width">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.mainVideoImagePair.youtube}`}
                  controls={true}
                  playing={true}
                  overVideo={true}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={post.mainVideoImagePair.imageId}
                    width="auto"
                    responsive
                  ></ImageMeta>
                </TheaterVideo>
              </div>
              <div className="column is-3"></div>
            </div>
          )}

          {post.mediaCoverage.images.length > 0 && (
            <section className="body-section color-back media-coverage">
              <div className="columns has-text-centered">
                <div className="column">
                  <h2>{post.mediaCoverage.heading}</h2>
                </div>
              </div>

              {post.mediaCoverage.images.length === 1 ? (
                <div className="columns is-desktop community-row">
                  <div className="column"></div>
                  <div className="column image-button-wrapper">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover-link"
                      href={post.mediaCoverage.images[0].href}
                      style={{ display: "block" }}
                    >
                      <NuvoImage
                        useAR
                        cloudName="nuvolum"
                        publicId={post.mediaCoverage.images[0].image}
                        width="auto"
                        responsive
                      ></NuvoImage>
                    </a>
                    {post.useButton ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={post.mediaCoverage.images[0].href}
                        className="standard-button image-button"
                      >
                        Learn More
                      </a>
                    ) : post.mediaCoverage.images[0].href.includes("youtube") ||
                      post.mediaCoverage.images[0].href.includes("youtu.be") ? (
                      <a
                        className="standard-button contained button-video"
                        style={{ marginTop: ".6666rem" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={post.mediaCoverage.images[0].href}
                      >
                        {playIcon} Watch Video
                      </a>
                    ) : (
                      <a
                        className="standard-button contained"
                        style={{ marginTop: ".6666rem" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={post.mediaCoverage.images[0].href}
                      >
                        Learn More
                      </a>
                    )}
                  </div>
                  <div className="column"></div>
                </div>
              ) : (
                <Grid pages={post.mediaCoverage.images} rowsOf={2} />
              )}
            </section>
          )}

          {post.gallery.prefix && (
            <section className="body-section community-gallery">
              <div className="columns has-text-centered">
                <div className="column is-3"></div>
                <div className="column">
                  <h2
                    style={{
                      marginTop:
                        post.mediaCoverage.images.length === 0 ? "0" : "40px"
                    }}
                  >
                    Gallery
                  </h2>
                  {post.gallery.usesYears && (
                    <div className="years-container">
                      <ul className="years-list">
                        {post.gallery.years.map((year) => (
                          <li key={year}>
                            <div className="time-check-container">
                              <div className="time-check">
                                <input
                                  onChange={handleYearChange(year)}
                                  type="checkbox"
                                  checked={years[year]}
                                  id={`time-check-${year}.`}
                                  value={`year-${year}`}
                                />
                                <label htmlFor={`time-check-${year}.`}>
                                  {year}
                                </label>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="column is-3"></div>
              </div>

              <div className="columns has-text-centered">
                <div className="column">
                  <GridGallery
                    pages={displayedPhotos}
                    rowsOf={3}
                    toggleDialog={toggleDialog}
                    location={location.pathname}
                  />
                  {post.gallery.bottomBlurb && (
                    <h4>{post.gallery.bottomBlurb}</h4>
                  )}
                </div>
              </div>
            </section>
          )}

          <section className="body-section community-get-started cta">
            <GetStarted
              sideColumnIs={2}
              centerHeading
              centerText
              heading={post.getStarted.heading}
              buttons={post.getStarted.buttons}
              paragraph={post.getStarted.blurb}
            />
          </section>

          <div>
            <Dialog
              className="community-dialog"
              lazy={false}
              backdropClassName="dark-background"
              portalClassName="show-cursor community-portal"
              isOpen={isDialogOpen}
              onClose={toggleDialog}
            >
              <CloseIcon onClick={toggleDialog} />
              <DialogGallery
                galleryIndex={galleryIndex}
                images={reactGalleryPhotos(displayedPhotos)}
              />
            </Dialog>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default SingleCommunity

function Grid(props) {
  // Split into rows of 2
  var chunk_size = props.rowsOf
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRow key={`media-coverage-row-${i}`} items={row} />
  ))
}

function GridRow(props) {
  const playIcon = (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="play"
      class="svg-inline--fa fa-play fa-w-14 button-icon"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
      ></path>
    </svg>
  )

  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        return (
          <>
            {i === 0 && <div className="column is-3"></div>}
            <div
              className={`column image-button-wrapper ${
                itemLen === i + 1 ? "is-8" : "is-8"
              }`}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="hover-link"
                href={item.href}
                style={{ display: "block" }}
              >
                <NuvoImage
                  useAR
                  cloudName="nuvolum"
                  publicId={item.image}
                  width="auto"
                  responsive
                ></NuvoImage>
              </a>
              {props.useButton ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                  className="standard-button image-button"
                >
                  Learn More
                </a>
              ) : item.href.includes("youtube") ||
                item.href.includes("youtu.be") ? (
                <a
                  className="standard-button contained button-video"
                  style={{ marginTop: ".6666rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                >
                  {playIcon} Watch Video
                </a>
              ) : (
                <a
                  className="standard-button contained"
                  style={{ marginTop: ".6666rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.href}
                >
                  Learn More
                </a>
              )}
            </div>
            {i === 0 && <div className="column is-2"></div>}
            {i === 1 && <div className="column is-3"></div>}
          </>
        )
      })}
    </div>
  )
}

function GridGallery(props) {
  // Split into rows of 2
  var chunk_size = props.rowsOf
  var arr = props.pages
  var groups = arr
    .map(function (e, i) {
      return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null
    })
    .filter(function (e) {
      return e
    })

  return groups.map((row, i) => (
    <GridRowGallery
      toggleDialog={props.toggleDialog}
      rowIndex={i - 1}
      key={`gallery-row-${i}`}
      items={row}
    />
  ))
}

function GridRowGallery(props) {
  const hasKeyword = (string, keyword) => {
    return string.toLowerCase().includes(keyword)
  }

  return (
    <div className="columns is-desktop community-row">
      {props.items.map((item, i) => {
        const itemLen = props.items.length
        if (itemLen === 1) {
          return (
            <>
              <div className="column is-2"></div>
              <div className={`column gallery-column first`}>
                <ImageMeta
                  noLazyload
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio={hasKeyword(item, "wisdom") ? "16:11" : "16:9"}
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                ></ImageMeta>
              </div>
              <div
                className={`column gallery-column vis-hidden`}
                style={{ visibility: "hidden" }}
              >
                <ImageMeta
                  noLazyload
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio={hasKeyword(item, "wisdom") ? "16:11" : "16:9"}
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                ></ImageMeta>
              </div>
              <div
                className={`column gallery-column vis-hidden last`}
                style={{ visibility: "hidden" }}
              >
                <ImageMeta
                  noLazyload
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio={hasKeyword(item, "wisdom") ? "16:11" : "16:9"}
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                ></ImageMeta>
              </div>
              <div className="column is-2"></div>
            </>
          )
        } else if (itemLen === 2) {
          return (
            <>
              {i === 0 && <div className="column is-2"></div>}
              <div
                className={`column gallery-column ${
                  i === itemLen - 1 ? "last" : i === 0 ? "first" : ""
                } ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}
              >
                <ImageMeta
                  noLazyload
                  onClick={() => {
                    props.toggleDialog(i + 3 * props.rowIndex)
                  }}
                  className="gallery-image"
                  cloudName="nuvolum"
                  publicId={item}
                  dpr="auto"
                  aspectRatio={hasKeyword(item, "wisdom") ? "16:11" : "16:9"}
                  responsive
                  width="auto"
                  crop="fill"
                  gravity="faces"
                  responsiveUseBreakpoints="true"
                ></ImageMeta>
              </div>
              {/* {i === 1 && (
                <div
                  className={`column gallery-column vis-hidden`}
                  style={{ visibility: "hidden" }}
                >
                  <ImageMeta
                    noLazyload
                    onClick={() => {
                      props.toggleDialog(i + 3 * props.rowIndex)
                    }}
                    className="gallery-image"
                    cloudName="nuvolum"
                    publicId={item}
                    dpr="auto"
                    aspectRatio={hasKeyword(item, "wisdom") ? "16:11" : "16:9"}
                    responsive
                    width="auto"
                    crop="fill"
                    gravity="faces"
                    responsiveUseBreakpoints="true"
                  ></ImageMeta>
                </div>
              )} */}
              {i === 1 && <div className="column is-2"></div>}
            </>
          )
        }
        return (
          <>
            {i === 0 && <div className="column is-2"></div>}
            <div
              className={`column gallery-column ${
                i === itemLen - 1 ? "last" : i === 0 ? "first" : ""
              } ${i === itemLen - 1 ? "last" : i === 0 ? "first" : ""}`}
            >
              <ImageMeta
                noLazyload
                onClick={() => {
                  props.toggleDialog(i + 3 * props.rowIndex)
                }}
                className="gallery-image"
                cloudName="nuvolum"
                publicId={item}
                dpr="auto"
                aspectRatio={hasKeyword(item, "wisdom") ? "16:11" : "16:9"}
                responsive
                width="auto"
                crop="fill"
                gravity="faces"
                responsiveUseBreakpoints="true"
              ></ImageMeta>
            </div>
            {i === 2 && <div className="column is-2"></div>}
          </>
        )
      })}
    </div>
  )
}

function DialogGallery(props) {
  return (
    <ImageGallery
      items={props.images}
      showThumbnails={false}
      showFullscreenButton={false}
      startIndex={props.galleryIndex + 3}
      lazyLoad={false}
    />
  )
}

export const query = graphql`
  query ($title: String!) {
    singleCommunity: allCommunitiesJson(filter: { title: { eq: $title } }) {
      nodes {
        blurb
        gallery {
          shortcode
          bottomBlurb
          prefix
          usesYears
          years
        }
        getStarted {
          buttons {
            button {
              appearance
              buttonText
              href
            }
          }
          heading
          blurb
        }
        heading
        id
        mainVideoImagePair {
          youtube
          imageId
        }
        mediaCoverage {
          images {
            image
            href
          }
          heading
        }
        metaDescription
        metaTitle
        schemaPage
        searchHeading
        title
      }
    }
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, reviewLanguage: { eq: "ENG" } }
    ) {
      nodes {
        ...FacewallData
        quote
        gender
        proceduresDone {
          procedureDone {
            procedureName
            procedureUrl
            otherProcedure
          }
        }
      }
    }
  }
`
